"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCardGuide = instanceOfCardGuide;
exports.CardGuideFromJSON = CardGuideFromJSON;
exports.CardGuideFromJSONTyped = CardGuideFromJSONTyped;
exports.CardGuideToJSON = CardGuideToJSON;
exports.CardGuideToJSONTyped = CardGuideToJSONTyped;
var CardGuideImage_1 = require("./CardGuideImage");
var CardGuideByline_1 = require("./CardGuideByline");
/**
 * Check if a given object implements the CardGuide interface.
 */
function instanceOfCardGuide(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('href' in value) || value['href'] === undefined)
        return false;
    if (!('image' in value) || value['image'] === undefined)
        return false;
    if (!('category' in value) || value['category'] === undefined)
        return false;
    if (!('byline' in value) || value['byline'] === undefined)
        return false;
    return true;
}
function CardGuideFromJSON(json) {
    return CardGuideFromJSONTyped(json, false);
}
function CardGuideFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'href': json['href'],
        'image': (0, CardGuideImage_1.CardGuideImageFromJSON)(json['image']),
        'category': json['category'],
        'byline': (0, CardGuideByline_1.CardGuideBylineFromJSON)(json['byline']),
    };
}
function CardGuideToJSON(json) {
    return CardGuideToJSONTyped(json, false);
}
function CardGuideToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'href': value['href'],
        'image': (0, CardGuideImage_1.CardGuideImageToJSON)(value['image']),
        'category': value['category'],
        'byline': (0, CardGuideByline_1.CardGuideBylineToJSON)(value['byline']),
    };
}
