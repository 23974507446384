"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfEventCard = instanceOfEventCard;
exports.EventCardFromJSON = EventCardFromJSON;
exports.EventCardFromJSONTyped = EventCardFromJSONTyped;
exports.EventCardToJSON = EventCardToJSON;
exports.EventCardToJSONTyped = EventCardToJSONTyped;
var EventOccurrenceLocation_1 = require("./EventOccurrenceLocation");
/**
 * Check if a given object implements the EventCard interface.
 */
function instanceOfEventCard(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('endDate' in value) || value['endDate'] === undefined)
        return false;
    if (!('address' in value) || value['address'] === undefined)
        return false;
    if (!('callToActionUrl' in value) || value['callToActionUrl'] === undefined)
        return false;
    if (!('callToActionLabel' in value) || value['callToActionLabel'] === undefined)
        return false;
    if (!('externalWebsiteUrl' in value) || value['externalWebsiteUrl'] === undefined)
        return false;
    if (!('externalWebsiteUrlText' in value) || value['externalWebsiteUrlText'] === undefined)
        return false;
    if (!('venueName' in value) || value['venueName'] === undefined)
        return false;
    if (!('location' in value) || value['location'] === undefined)
        return false;
    if (!('zipCode' in value) || value['zipCode'] === undefined)
        return false;
    if (!('city' in value) || value['city'] === undefined)
        return false;
    return true;
}
function EventCardFromJSON(json) {
    return EventCardFromJSONTyped(json, false);
}
function EventCardFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'image': json['image'] == null ? undefined : json['image'],
        'startDate': json['start_date'] == null ? undefined : json['start_date'],
        'endDate': (new Date(json['end_date'])),
        'href': json['href'] == null ? undefined : json['href'],
        'categories': json['categories'] == null ? undefined : json['categories'],
        'category': json['category'] == null ? undefined : json['category'],
        'subcategory': json['subcategory'] == null ? undefined : json['subcategory'],
        'size': json['size'] == null ? undefined : json['size'],
        'address': json['address'],
        'callToActionUrl': json['call_to_action_url'],
        'callToActionLabel': json['call_to_action_label'],
        'backendModifiers': json['backend_modifiers'] == null ? undefined : json['backend_modifiers'],
        'externalWebsiteUrl': json['external_website_url'],
        'externalWebsiteUrlText': json['external_website_url_text'],
        'venueName': json['venue_name'],
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationFromJSON)(json['location']),
        'closestStation': json['closest_station'] == null ? undefined : json['closest_station'],
        'zipCode': json['zip_code'],
        'city': json['city'],
    };
}
function EventCardToJSON(json) {
    return EventCardToJSONTyped(json, false);
}
function EventCardToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'end_date': ((value['endDate']).toISOString().substring(0, 10)),
        'address': value['address'],
        'call_to_action_url': value['callToActionUrl'],
        'call_to_action_label': value['callToActionLabel'],
        'external_website_url': value['externalWebsiteUrl'],
        'external_website_url_text': value['externalWebsiteUrlText'],
        'venue_name': value['venueName'],
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationToJSON)(value['location']),
        'zip_code': value['zipCode'],
        'city': value['city'],
    };
}
