"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfObjectTeaserMassInfoInner = instanceOfObjectTeaserMassInfoInner;
exports.ObjectTeaserMassInfoInnerFromJSON = ObjectTeaserMassInfoInnerFromJSON;
exports.ObjectTeaserMassInfoInnerFromJSONTyped = ObjectTeaserMassInfoInnerFromJSONTyped;
exports.ObjectTeaserMassInfoInnerToJSON = ObjectTeaserMassInfoInnerToJSON;
exports.ObjectTeaserMassInfoInnerToJSONTyped = ObjectTeaserMassInfoInnerToJSONTyped;
/**
 * Check if a given object implements the ObjectTeaserMassInfoInner interface.
 */
function instanceOfObjectTeaserMassInfoInner(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    return true;
}
function ObjectTeaserMassInfoInnerFromJSON(json) {
    return ObjectTeaserMassInfoInnerFromJSONTyped(json, false);
}
function ObjectTeaserMassInfoInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
    };
}
function ObjectTeaserMassInfoInnerToJSON(json) {
    return ObjectTeaserMassInfoInnerToJSONTyped(json, false);
}
function ObjectTeaserMassInfoInnerToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
    };
}
