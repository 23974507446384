"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCardGuideBylineAuthor = instanceOfCardGuideBylineAuthor;
exports.CardGuideBylineAuthorFromJSON = CardGuideBylineAuthorFromJSON;
exports.CardGuideBylineAuthorFromJSONTyped = CardGuideBylineAuthorFromJSONTyped;
exports.CardGuideBylineAuthorToJSON = CardGuideBylineAuthorToJSON;
exports.CardGuideBylineAuthorToJSONTyped = CardGuideBylineAuthorToJSONTyped;
/**
 * Check if a given object implements the CardGuideBylineAuthor interface.
 */
function instanceOfCardGuideBylineAuthor(value) {
    return true;
}
function CardGuideBylineAuthorFromJSON(json) {
    return CardGuideBylineAuthorFromJSONTyped(json, false);
}
function CardGuideBylineAuthorFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'] == null ? undefined : json['name'],
        'title': json['title'] == null ? undefined : json['title'],
        'image': json['image'] == null ? undefined : json['image'],
        'href': json['href'] == null ? undefined : json['href'],
        'text': json['text'] == null ? undefined : json['text'],
        'isLocal': json['is_local'] == null ? undefined : json['is_local'],
    };
}
function CardGuideBylineAuthorToJSON(json) {
    return CardGuideBylineAuthorToJSONTyped(json, false);
}
function CardGuideBylineAuthorToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {};
}
