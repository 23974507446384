"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfEventOccurrenceCategoriesInner = instanceOfEventOccurrenceCategoriesInner;
exports.EventOccurrenceCategoriesInnerFromJSON = EventOccurrenceCategoriesInnerFromJSON;
exports.EventOccurrenceCategoriesInnerFromJSONTyped = EventOccurrenceCategoriesInnerFromJSONTyped;
exports.EventOccurrenceCategoriesInnerToJSON = EventOccurrenceCategoriesInnerToJSON;
exports.EventOccurrenceCategoriesInnerToJSONTyped = EventOccurrenceCategoriesInnerToJSONTyped;
/**
 * Check if a given object implements the EventOccurrenceCategoriesInner interface.
 */
function instanceOfEventOccurrenceCategoriesInner(value) {
    if (!('slug' in value) || value['slug'] === undefined)
        return false;
    return true;
}
function EventOccurrenceCategoriesInnerFromJSON(json) {
    return EventOccurrenceCategoriesInnerFromJSONTyped(json, false);
}
function EventOccurrenceCategoriesInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['title'] == null ? undefined : json['title'],
        'slug': json['slug'],
        'subcategories': json['subcategories'] == null ? undefined : json['subcategories'],
    };
}
function EventOccurrenceCategoriesInnerToJSON(json) {
    return EventOccurrenceCategoriesInnerToJSONTyped(json, false);
}
function EventOccurrenceCategoriesInnerToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'slug': value['slug'],
    };
}
