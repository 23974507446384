"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfEventOccurrence = instanceOfEventOccurrence;
exports.EventOccurrenceFromJSON = EventOccurrenceFromJSON;
exports.EventOccurrenceFromJSONTyped = EventOccurrenceFromJSONTyped;
exports.EventOccurrenceToJSON = EventOccurrenceToJSON;
exports.EventOccurrenceToJSONTyped = EventOccurrenceToJSONTyped;
var EventOccurrenceCategoriesInner_1 = require("./EventOccurrenceCategoriesInner");
var EventOccurrenceLocation_1 = require("./EventOccurrenceLocation");
/**
 * Check if a given object implements the EventOccurrence interface.
 */
function instanceOfEventOccurrence(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('externalWebsiteUrl' in value) || value['externalWebsiteUrl'] === undefined)
        return false;
    if (!('callToActionUrl' in value) || value['callToActionUrl'] === undefined)
        return false;
    if (!('address' in value) || value['address'] === undefined)
        return false;
    if (!('venueName' in value) || value['venueName'] === undefined)
        return false;
    if (!('zipCode' in value) || value['zipCode'] === undefined)
        return false;
    if (!('city' in value) || value['city'] === undefined)
        return false;
    if (!('location' in value) || value['location'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('modifiedAt' in value) || value['modifiedAt'] === undefined)
        return false;
    if (!('date' in value) || value['date'] === undefined)
        return false;
    if (!('startDate' in value) || value['startDate'] === undefined)
        return false;
    if (!('endDate' in value) || value['endDate'] === undefined)
        return false;
    if (!('startTime' in value) || value['startTime'] === undefined)
        return false;
    if (!('endTime' in value) || value['endTime'] === undefined)
        return false;
    if (!('categories' in value) || value['categories'] === undefined)
        return false;
    if (!('closestStation' in value) || value['closestStation'] === undefined)
        return false;
    return true;
}
function EventOccurrenceFromJSON(json) {
    return EventOccurrenceFromJSONTyped(json, false);
}
function EventOccurrenceFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'externalWebsiteUrl': json['external_website_url'],
        'callToActionUrl': json['call_to_action_url'],
        'url': json['url'] == null ? undefined : json['url'],
        'address': json['address'],
        'venueName': json['venue_name'],
        'zipCode': json['zip_code'],
        'city': json['city'],
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationFromJSON)(json['location']),
        'createdAt': (new Date(json['created_at'])),
        'modifiedAt': (new Date(json['modified_at'])),
        'date': (new Date(json['date'])),
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'startTime': json['start_time'],
        'endTime': json['end_time'],
        'categories': (json['categories'].map(EventOccurrenceCategoriesInner_1.EventOccurrenceCategoriesInnerFromJSON)),
        'featuredImage': json['featured_image'] == null ? undefined : json['featured_image'],
        'closestStation': json['closest_station'],
    };
}
function EventOccurrenceToJSON(json) {
    return EventOccurrenceToJSONTyped(json, false);
}
function EventOccurrenceToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'external_website_url': value['externalWebsiteUrl'],
        'call_to_action_url': value['callToActionUrl'],
        'address': value['address'],
        'venue_name': value['venueName'],
        'zip_code': value['zipCode'],
        'city': value['city'],
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationToJSON)(value['location']),
        'created_at': ((value['createdAt']).toISOString()),
        'modified_at': ((value['modifiedAt']).toISOString()),
        'date': ((value['date']).toISOString().substring(0, 10)),
        'start_date': ((value['startDate']).toISOString().substring(0, 10)),
        'end_date': ((value['endDate']).toISOString().substring(0, 10)),
        'start_time': value['startTime'],
        'end_time': value['endTime'],
        'categories': (value['categories'].map(EventOccurrenceCategoriesInner_1.EventOccurrenceCategoriesInnerToJSON)),
        'closest_station': value['closestStation'],
    };
}
