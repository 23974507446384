"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfListEvents200ResponseMeta = instanceOfListEvents200ResponseMeta;
exports.ListEvents200ResponseMetaFromJSON = ListEvents200ResponseMetaFromJSON;
exports.ListEvents200ResponseMetaFromJSONTyped = ListEvents200ResponseMetaFromJSONTyped;
exports.ListEvents200ResponseMetaToJSON = ListEvents200ResponseMetaToJSON;
exports.ListEvents200ResponseMetaToJSONTyped = ListEvents200ResponseMetaToJSONTyped;
var ListEvents200ResponseMetaCategoriesInner_1 = require("./ListEvents200ResponseMetaCategoriesInner");
/**
 * Check if a given object implements the ListEvents200ResponseMeta interface.
 */
function instanceOfListEvents200ResponseMeta(value) {
    return true;
}
function ListEvents200ResponseMetaFromJSON(json) {
    return ListEvents200ResponseMetaFromJSONTyped(json, false);
}
function ListEvents200ResponseMetaFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'categories': json['categories'] == null ? undefined : (json['categories'].map(ListEvents200ResponseMetaCategoriesInner_1.ListEvents200ResponseMetaCategoriesInnerFromJSON)),
    };
}
function ListEvents200ResponseMetaToJSON(json) {
    return ListEvents200ResponseMetaToJSONTyped(json, false);
}
function ListEvents200ResponseMetaToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'categories': value['categories'] == null ? undefined : (value['categories'].map(ListEvents200ResponseMetaCategoriesInner_1.ListEvents200ResponseMetaCategoriesInnerToJSON)),
    };
}
