"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCardGuideImage = instanceOfCardGuideImage;
exports.CardGuideImageFromJSON = CardGuideImageFromJSON;
exports.CardGuideImageFromJSONTyped = CardGuideImageFromJSONTyped;
exports.CardGuideImageToJSON = CardGuideImageToJSON;
exports.CardGuideImageToJSONTyped = CardGuideImageToJSONTyped;
/**
 * Check if a given object implements the CardGuideImage interface.
 */
function instanceOfCardGuideImage(value) {
    return true;
}
function CardGuideImageFromJSON(json) {
    return CardGuideImageFromJSONTyped(json, false);
}
function CardGuideImageFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['title'] == null ? undefined : json['title'],
        'url': json['url'] == null ? undefined : json['url'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'renditions': json['renditions'] == null ? undefined : json['renditions'],
        'fileSize': json['file_size'] == null ? undefined : json['file_size'],
        'focal': json['focal'] == null ? undefined : json['focal'],
        'caption': json['caption'] == null ? undefined : json['caption'],
        'alt': json['alt'] == null ? undefined : json['alt'],
        'id': json['id'] == null ? undefined : json['id'],
        'schema': json['schema'] == null ? undefined : json['schema'],
    };
}
function CardGuideImageToJSON(json) {
    return CardGuideImageToJSONTyped(json, false);
}
function CardGuideImageToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {};
}
