"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfObjectTeaser = instanceOfObjectTeaser;
exports.ObjectTeaserFromJSON = ObjectTeaserFromJSON;
exports.ObjectTeaserFromJSONTyped = ObjectTeaserFromJSONTyped;
exports.ObjectTeaserToJSON = ObjectTeaserToJSON;
exports.ObjectTeaserToJSONTyped = ObjectTeaserToJSONTyped;
var EventOccurrenceLocation_1 = require("./EventOccurrenceLocation");
var ObjectTeaserMassInfoInner_1 = require("./ObjectTeaserMassInfoInner");
var CardGuideImage_1 = require("./CardGuideImage");
/**
 * Check if a given object implements the ObjectTeaser interface.
 */
function instanceOfObjectTeaser(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('label' in value) || value['label'] === undefined)
        return false;
    if (!('text' in value) || value['text'] === undefined)
        return false;
    if (!('href' in value) || value['href'] === undefined)
        return false;
    if (!('image' in value) || value['image'] === undefined)
        return false;
    if (!('location' in value) || value['location'] === undefined)
        return false;
    if (!('slug' in value) || value['slug'] === undefined)
        return false;
    if (!('massInfo' in value) || value['massInfo'] === undefined)
        return false;
    return true;
}
function ObjectTeaserFromJSON(json) {
    return ObjectTeaserFromJSONTyped(json, false);
}
function ObjectTeaserFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'type': json['type'] == null ? undefined : json['type'],
        'label': json['label'],
        'text': json['text'],
        'href': json['href'],
        'image': (0, CardGuideImage_1.CardGuideImageFromJSON)(json['image']),
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationFromJSON)(json['location']),
        'slug': json['slug'],
        'infoBox': json['info_box'] == null ? undefined : json['info_box'],
        'infoBoxText': json['info_box_text'] == null ? undefined : json['info_box_text'],
        'massInfo': (json['mass_info'].map(ObjectTeaserMassInfoInner_1.ObjectTeaserMassInfoInnerFromJSON)),
    };
}
function ObjectTeaserToJSON(json) {
    return ObjectTeaserToJSONTyped(json, false);
}
function ObjectTeaserToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'label': value['label'],
        'text': value['text'],
        'href': value['href'],
        'image': (0, CardGuideImage_1.CardGuideImageToJSON)(value['image']),
        'location': (0, EventOccurrenceLocation_1.EventOccurrenceLocationToJSON)(value['location']),
        'slug': value['slug'],
        'mass_info': (value['massInfo'].map(ObjectTeaserMassInfoInner_1.ObjectTeaserMassInfoInnerToJSON)),
    };
}
