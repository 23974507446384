"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfListObjectPages200Response = instanceOfListObjectPages200Response;
exports.ListObjectPages200ResponseFromJSON = ListObjectPages200ResponseFromJSON;
exports.ListObjectPages200ResponseFromJSONTyped = ListObjectPages200ResponseFromJSONTyped;
exports.ListObjectPages200ResponseToJSON = ListObjectPages200ResponseToJSON;
exports.ListObjectPages200ResponseToJSONTyped = ListObjectPages200ResponseToJSONTyped;
var ObjectTeaser_1 = require("./ObjectTeaser");
/**
 * Check if a given object implements the ListObjectPages200Response interface.
 */
function instanceOfListObjectPages200Response(value) {
    return true;
}
function ListObjectPages200ResponseFromJSON(json) {
    return ListObjectPages200ResponseFromJSONTyped(json, false);
}
function ListObjectPages200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'] == null ? undefined : json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': json['results'] == null ? undefined : (json['results'].map(ObjectTeaser_1.ObjectTeaserFromJSON)),
    };
}
function ListObjectPages200ResponseToJSON(json) {
    return ListObjectPages200ResponseToJSONTyped(json, false);
}
function ListObjectPages200ResponseToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': value['results'] == null ? undefined : (value['results'].map(ObjectTeaser_1.ObjectTeaserToJSON)),
    };
}
