"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfListEvents200Response = instanceOfListEvents200Response;
exports.ListEvents200ResponseFromJSON = ListEvents200ResponseFromJSON;
exports.ListEvents200ResponseFromJSONTyped = ListEvents200ResponseFromJSONTyped;
exports.ListEvents200ResponseToJSON = ListEvents200ResponseToJSON;
exports.ListEvents200ResponseToJSONTyped = ListEvents200ResponseToJSONTyped;
var EventCard_1 = require("./EventCard");
var ListEvents200ResponseMeta_1 = require("./ListEvents200ResponseMeta");
/**
 * Check if a given object implements the ListEvents200Response interface.
 */
function instanceOfListEvents200Response(value) {
    return true;
}
function ListEvents200ResponseFromJSON(json) {
    return ListEvents200ResponseFromJSONTyped(json, false);
}
function ListEvents200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'] == null ? undefined : json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': json['results'] == null ? undefined : (json['results'].map(EventCard_1.EventCardFromJSON)),
        'meta': json['meta'] == null ? undefined : (0, ListEvents200ResponseMeta_1.ListEvents200ResponseMetaFromJSON)(json['meta']),
        'totalPages': json['total_pages'] == null ? undefined : json['total_pages'],
        'currentPage': json['current_page'] == null ? undefined : json['current_page'],
    };
}
function ListEvents200ResponseToJSON(json) {
    return ListEvents200ResponseToJSONTyped(json, false);
}
function ListEvents200ResponseToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': value['results'] == null ? undefined : (value['results'].map(EventCard_1.EventCardToJSON)),
        'meta': (0, ListEvents200ResponseMeta_1.ListEvents200ResponseMetaToJSON)(value['meta']),
        'total_pages': value['totalPages'],
        'current_page': value['currentPage'],
    };
}
