"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfListEventOccurrences200ResponseMeta = instanceOfListEventOccurrences200ResponseMeta;
exports.ListEventOccurrences200ResponseMetaFromJSON = ListEventOccurrences200ResponseMetaFromJSON;
exports.ListEventOccurrences200ResponseMetaFromJSONTyped = ListEventOccurrences200ResponseMetaFromJSONTyped;
exports.ListEventOccurrences200ResponseMetaToJSON = ListEventOccurrences200ResponseMetaToJSON;
exports.ListEventOccurrences200ResponseMetaToJSONTyped = ListEventOccurrences200ResponseMetaToJSONTyped;
var ListEventOccurrences200ResponseMetaCategoriesInner_1 = require("./ListEventOccurrences200ResponseMetaCategoriesInner");
/**
 * Check if a given object implements the ListEventOccurrences200ResponseMeta interface.
 */
function instanceOfListEventOccurrences200ResponseMeta(value) {
    return true;
}
function ListEventOccurrences200ResponseMetaFromJSON(json) {
    return ListEventOccurrences200ResponseMetaFromJSONTyped(json, false);
}
function ListEventOccurrences200ResponseMetaFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'categories': json['categories'] == null ? undefined : (json['categories'].map(ListEventOccurrences200ResponseMetaCategoriesInner_1.ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON)),
    };
}
function ListEventOccurrences200ResponseMetaToJSON(json) {
    return ListEventOccurrences200ResponseMetaToJSONTyped(json, false);
}
function ListEventOccurrences200ResponseMetaToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'categories': value['categories'] == null ? undefined : (value['categories'].map(ListEventOccurrences200ResponseMetaCategoriesInner_1.ListEventOccurrences200ResponseMetaCategoriesInnerToJSON)),
    };
}
