"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfListEventOccurrences200ResponseMetaCategoriesInner = instanceOfListEventOccurrences200ResponseMetaCategoriesInner;
exports.ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON = ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON;
exports.ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped = ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped;
exports.ListEventOccurrences200ResponseMetaCategoriesInnerToJSON = ListEventOccurrences200ResponseMetaCategoriesInnerToJSON;
exports.ListEventOccurrences200ResponseMetaCategoriesInnerToJSONTyped = ListEventOccurrences200ResponseMetaCategoriesInnerToJSONTyped;
var ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInner_1 = require("./ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInner");
/**
 * Check if a given object implements the ListEventOccurrences200ResponseMetaCategoriesInner interface.
 */
function instanceOfListEventOccurrences200ResponseMetaCategoriesInner(value) {
    return true;
}
function ListEventOccurrences200ResponseMetaCategoriesInnerFromJSON(json) {
    return ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped(json, false);
}
function ListEventOccurrences200ResponseMetaCategoriesInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['title'] == null ? undefined : json['title'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'count': json['count'] == null ? undefined : json['count'],
        'subcategories': json['subcategories'] == null ? undefined : (json['subcategories'].map(ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInner_1.ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerFromJSON)),
    };
}
function ListEventOccurrences200ResponseMetaCategoriesInnerToJSON(json) {
    return ListEventOccurrences200ResponseMetaCategoriesInnerToJSONTyped(json, false);
}
function ListEventOccurrences200ResponseMetaCategoriesInnerToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'title': value['title'],
        'slug': value['slug'],
        'count': value['count'],
        'subcategories': value['subcategories'] == null ? undefined : (value['subcategories'].map(ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInner_1.ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerToJSON)),
    };
}
