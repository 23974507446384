"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInner = instanceOfListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInner;
exports.ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerFromJSON = ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerFromJSON;
exports.ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerFromJSONTyped = ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerFromJSONTyped;
exports.ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerToJSON = ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerToJSON;
exports.ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerToJSONTyped = ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerToJSONTyped;
/**
 * Check if a given object implements the ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInner interface.
 */
function instanceOfListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInner(value) {
    return true;
}
function ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerFromJSON(json) {
    return ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerFromJSONTyped(json, false);
}
function ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['title'] == null ? undefined : json['title'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'hasEvents': json['has_events'] == null ? undefined : json['has_events'],
    };
}
function ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerToJSON(json) {
    return ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerToJSONTyped(json, false);
}
function ListEventOccurrences200ResponseMetaCategoriesInnerSubcategoriesInnerToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'title': value['title'],
        'slug': value['slug'],
        'has_events': value['hasEvents'],
    };
}
