"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCardGuideByline = instanceOfCardGuideByline;
exports.CardGuideBylineFromJSON = CardGuideBylineFromJSON;
exports.CardGuideBylineFromJSONTyped = CardGuideBylineFromJSONTyped;
exports.CardGuideBylineToJSON = CardGuideBylineToJSON;
exports.CardGuideBylineToJSONTyped = CardGuideBylineToJSONTyped;
var CardGuideBylineAuthor_1 = require("./CardGuideBylineAuthor");
/**
 * Check if a given object implements the CardGuideByline interface.
 */
function instanceOfCardGuideByline(value) {
    if (!('author' in value) || value['author'] === undefined)
        return false;
    return true;
}
function CardGuideBylineFromJSON(json) {
    return CardGuideBylineFromJSONTyped(json, false);
}
function CardGuideBylineFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'image': json['image'] == null ? undefined : json['image'],
        'author': (0, CardGuideBylineAuthor_1.CardGuideBylineAuthorFromJSON)(json['author']),
        'color': json['color'] == null ? undefined : json['color'],
        'size': json['size'] == null ? undefined : json['size'],
        'headline': json['headline'] == null ? undefined : json['headline'],
        'text': json['text'] == null ? undefined : json['text'],
    };
}
function CardGuideBylineToJSON(json) {
    return CardGuideBylineToJSONTyped(json, false);
}
function CardGuideBylineToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'author': (0, CardGuideBylineAuthor_1.CardGuideBylineAuthorToJSON)(value['author']),
    };
}
