"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfListGuidePages200Response = instanceOfListGuidePages200Response;
exports.ListGuidePages200ResponseFromJSON = ListGuidePages200ResponseFromJSON;
exports.ListGuidePages200ResponseFromJSONTyped = ListGuidePages200ResponseFromJSONTyped;
exports.ListGuidePages200ResponseToJSON = ListGuidePages200ResponseToJSON;
exports.ListGuidePages200ResponseToJSONTyped = ListGuidePages200ResponseToJSONTyped;
var CardGuide_1 = require("./CardGuide");
/**
 * Check if a given object implements the ListGuidePages200Response interface.
 */
function instanceOfListGuidePages200Response(value) {
    return true;
}
function ListGuidePages200ResponseFromJSON(json) {
    return ListGuidePages200ResponseFromJSONTyped(json, false);
}
function ListGuidePages200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'] == null ? undefined : json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': json['results'] == null ? undefined : (json['results'].map(CardGuide_1.CardGuideFromJSON)),
    };
}
function ListGuidePages200ResponseToJSON(json) {
    return ListGuidePages200ResponseToJSONTyped(json, false);
}
function ListGuidePages200ResponseToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': value['results'] == null ? undefined : (value['results'].map(CardGuide_1.CardGuideToJSON)),
    };
}
