"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfListEventOccurrences200Response = instanceOfListEventOccurrences200Response;
exports.ListEventOccurrences200ResponseFromJSON = ListEventOccurrences200ResponseFromJSON;
exports.ListEventOccurrences200ResponseFromJSONTyped = ListEventOccurrences200ResponseFromJSONTyped;
exports.ListEventOccurrences200ResponseToJSON = ListEventOccurrences200ResponseToJSON;
exports.ListEventOccurrences200ResponseToJSONTyped = ListEventOccurrences200ResponseToJSONTyped;
var ListEventOccurrences200ResponseMeta_1 = require("./ListEventOccurrences200ResponseMeta");
var EventOccurrence_1 = require("./EventOccurrence");
/**
 * Check if a given object implements the ListEventOccurrences200Response interface.
 */
function instanceOfListEventOccurrences200Response(value) {
    return true;
}
function ListEventOccurrences200ResponseFromJSON(json) {
    return ListEventOccurrences200ResponseFromJSONTyped(json, false);
}
function ListEventOccurrences200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'] == null ? undefined : json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': json['results'] == null ? undefined : (json['results'].map(EventOccurrence_1.EventOccurrenceFromJSON)),
        'meta': json['meta'] == null ? undefined : (0, ListEventOccurrences200ResponseMeta_1.ListEventOccurrences200ResponseMetaFromJSON)(json['meta']),
    };
}
function ListEventOccurrences200ResponseToJSON(json) {
    return ListEventOccurrences200ResponseToJSONTyped(json, false);
}
function ListEventOccurrences200ResponseToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': value['results'] == null ? undefined : (value['results'].map(EventOccurrence_1.EventOccurrenceToJSON)),
        'meta': (0, ListEventOccurrences200ResponseMeta_1.ListEventOccurrences200ResponseMetaToJSON)(value['meta']),
    };
}
