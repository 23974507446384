"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SBR Public API
 * Open api
 *
 * The version of the OpenAPI document: 1.29.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfEventOccurrenceLocation = instanceOfEventOccurrenceLocation;
exports.EventOccurrenceLocationFromJSON = EventOccurrenceLocationFromJSON;
exports.EventOccurrenceLocationFromJSONTyped = EventOccurrenceLocationFromJSONTyped;
exports.EventOccurrenceLocationToJSON = EventOccurrenceLocationToJSON;
exports.EventOccurrenceLocationToJSONTyped = EventOccurrenceLocationToJSONTyped;
/**
 * Check if a given object implements the EventOccurrenceLocation interface.
 */
function instanceOfEventOccurrenceLocation(value) {
    if (!('latitude' in value) || value['latitude'] === undefined)
        return false;
    if (!('longitude' in value) || value['longitude'] === undefined)
        return false;
    return true;
}
function EventOccurrenceLocationFromJSON(json) {
    return EventOccurrenceLocationFromJSONTyped(json, false);
}
function EventOccurrenceLocationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}
function EventOccurrenceLocationToJSON(json) {
    return EventOccurrenceLocationToJSONTyped(json, false);
}
function EventOccurrenceLocationToJSONTyped(value, ignoreDiscriminator) {
    if (ignoreDiscriminator === void 0) { ignoreDiscriminator = false; }
    if (value == null) {
        return value;
    }
    return {
        'latitude': value['latitude'],
        'longitude': value['longitude'],
    };
}
